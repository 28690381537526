import React, { memo, useEffect, useState } from 'react';
import Avatar from 'react-avatar';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FaRegBuilding, FaRegBell } from 'react-icons/fa';
import PropTypes from 'prop-types';

import { ReactComponent as IconOrg } from '~/assets/icons/icon_org.svg';
import { ReactComponent as IconChartPie } from '~/assets/icons/icon_chartpie.svg';
import { ReactComponent as IconRobot } from '~/assets/icons/icon_robot.svg';
import { ReactComponent as IconURA } from '~/assets/icons/icon_ura.svg';
import { ReactComponent as IconDocument } from '~/assets/icons/icon_faregbook.svg';
import { ReactComponent as IconTachometer } from '~/assets/icons/icon_tachometer.svg';

import history from '../../../../services/history';
import {
  signOut,
  updateSelectOrganization,
} from '~/store/modules/auth/actions';
import { fetchCompany } from '~/store/modules/company/actions';
import { fetchOrganizationsFromCompany } from '~/store/modules/organization/actions';
import { fetchAllConfigIvrOmni } from '~/store/modules/ivr/actions';

import { isRootUser, isViewer } from '~/utils/utils';

import SideBar from '~/components/SideBar';

const SideMenu = ({ isOpened, onToggle }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { loggedUser } = useSelector(({ auth }) => auth);
  const { isUserViewerRedator } = useSelector(({ session }) => session);
  const { company } = useSelector((state) => state.company);
  const { organizations } = useSelector((state) => state.organization);
  const ivrConfigs = useSelector((state) => state?.ivr?.configsIvrOmni);

  const isRoot = isRootUser(loggedUser?.selectedCompany);
  const showIvrMenuOption = ivrConfigs?.length > 0;
  const showIvrReportMenuOption =
    showIvrMenuOption && !isViewer(loggedUser?.selectedCompany);

  const [selectedOrg, setSelectedOrg] = useState('');
  const [userOrganizations, setUserOrganizations] = useState([]);
  useEffect(() => {
    const organizationId = loggedUser?.selectedOrganization;
    if (!organizationId) return;

    dispatch(fetchAllConfigIvrOmni({ payload: organizationId }));
  }, [loggedUser?.selectedOrganization]);

  useEffect(() => {
    if (loggedUser && loggedUser.selectedCompany) {
      if (company && company?._id !== loggedUser.selectedCompany._id) {
        dispatch(fetchCompany({ companyId: loggedUser.selectedCompany._id }));
      }

      if (organizations && organizations.length === 0) {
        dispatch(
          fetchOrganizationsFromCompany({
            companyId: loggedUser.selectedCompany._id,
          })
        );
      }
    }

    if (organizations && loggedUser) {
      const [org] = organizations.filter(
        (organization) => organization._id === loggedUser.selectedOrganization
      );

      const userOrgs = loggedUser.isRootUser
        ? organizations
        : loggedUser.organizations.filter((organization) => {
            return company._id === organization.companyId;
          });

      setUserOrganizations(userOrgs || org);
      setSelectedOrg(org || userOrgs[0]);
    }
  }, [company, organizations, loggedUser]);

  const avatarProfile = () => {
    if (loggedUser?.profilePhoto) {
      return <img alt="Profile avatar" src={loggedUser?.profilePhoto} />;
    }

    return (
      <Avatar
        className="profile-img"
        name={loggedUser?.name}
        size="33"
        round
        color={Avatar.getRandomColor('sitebase', [
          '#d73d32',
          '#7e3794',
          '#4285f4',
          '#67ae3f',
          '#d61a7f',
          '#ff4080',
        ])}
      />
    );
  };

  const changeOrganization = (org) => {
    dispatch(updateSelectOrganization(org._id));
    setSelectedOrg(org);
    history.push('/bots');
  };

  const handleSignOut = () => {
    dispatch(signOut());
  };

  const handleProfileMenu = () => {
    history.push('/profile');
  };

  const changeCompany = () => {
    history.push('/select-company-organization');
  };

  const handlePreferencesMenu = () => {
    history.push('/preferences/general');
  };

  const menuCompanies = () => {
    const menu = [];

    const claimUser =
      loggedUser?.selectedCompany?.claim?.name?.toLowerCase?.() || '';

    if (claimUser === 'admin' || isRoot) {
      menu.push({
        name: t('side_menu.preferences'),
        action: handlePreferencesMenu,
      });
    }

    if (loggedUser && (isRoot || loggedUser?.companies?.length > 1)) {
      menu.push({ name: t('side_menu.change_company'), action: changeCompany });
    }
    return menu;
  };

  const menuOrgs = () => {
    let menu = [];

    menu = userOrganizations.map((el) => {
      return {
        ...el,
        id: el._id,
        action: changeOrganization,
      };
    });

    return menu;
  };

  const profile = [
    {
      image_url: avatarProfile(),
      setor: t('side_menu.profile'),
      name: loggedUser?.name,
      route: '/profile',
      menu: [
        { name: t('side_menu.profile'), action: handleProfileMenu },
        { name: t('side_menu.logout'), action: handleSignOut },
      ],
    },
    {
      icon: <FaRegBuilding />,
      setor: t('side_menu.company'),
      route: '/preferences',
      name: company?.fantasyName,
      menu: menuCompanies(),
    },
    {
      icon: <IconOrg />,
      setor: t('side_menu.organization'),
      route: '',
      name: selectedOrg?.name,
      menu: menuOrgs(),
    },
  ];

  const menuOptions = [
    {
      icon: <FaRegBell />,
      title: t('side_menu.news'),
      name: '',
      tooltip: true,
      disabled: true,
      route: '/news',
    },
    { separator: true },
    {
      icon: <IconRobot />,
      title: t('side_menu.bots'),
      name: '',
      tooltip: true,
      disabled: false,
      route: '/bots',
    },
    {
      icon: <IconURA />,
      title: t('side_menu.ivrs'),
      name: '',
      tooltip: true,
      hidden: !showIvrMenuOption,
      route: '/ivrs',
    },
    {
      icon: <IconChartPie />,
      title: t('side_menu.bot_reports'),
      name: '',
      tooltip: true,
      disabled: isUserViewerRedator,
      route: '/bot-reports',
    },
    {
      icon: <IconChartPie />,
      title: t('side_menu.ivr_reports'),
      name: '',
      tooltip: true,
      hidden: !showIvrReportMenuOption,
      route: '/ivr-reports',
    },
    {
      icon: <IconTachometer />,
      title: t('side_menu.company_reports'),
      name: '',
      tooltip: true,
      disabled: isUserViewerRedator,
      route: '/company-reports',
    },
    { separator: true },
    {
      icon: <IconDocument />,
      title: t('side_menu.documentation'),
      name: '',
      tooltip: true,
      disabled: false,
      route: 'https://docs.boteria.com.br/',
    },
    // {
    //   icon: <FaRegQuestionCircle />,
    //   title: t('side_menu.help'),
    //   name: '',
    //   tooltip: true,
    //   disabled: false,
    //   route: 'https://code7.com/faq/boteria/',
    // },
  ];

  return (
    <SideBar
      profiles={profile}
      menuOptions={menuOptions}
      isOpened={isOpened}
      onToggle={onToggle}
    />
  );
};

SideMenu.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
};

export default memo(SideMenu);
