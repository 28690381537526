import types from './types';

export const fetchDataStart = ({ botId, subflowId, isFork }) => ({
  type: types.FETCH_DATA_START,
  payload: { botId, subflowId, isFork },
});
export const fetchDataSuccess = () => ({
  type: types.FETCH_DATA_SUCCESS,
});
export const fetchDataFailure = () => ({
  type: types.FETCH_DATA_FAILURE,
});
export const setIsLoading = (isLoading) => ({
  type: types.SET_IS_LOADING,
  payload: isLoading,
});
export const setIsRenderFlow = (isRenderFlow) => ({
  type: types.SET_RENDER_FLOW,
  payload: isRenderFlow,
});

export const setClientPosition = ({ x, y }) => ({
  type: types.SET_CLIENT_POSITION,
  payload: { x, y },
});

export const setCopyPasteItems = ({ botId, selectedNodes, selectedEdges }) => ({
  type: types.SET_COPY_PASTE_ITEMS,
  payload: { botId, selectedNodes, selectedEdges },
});
