import { combineReducers } from 'redux';

import authTypes from './auth/types';

import { auth, tfaStatus } from './auth/reducer';
import bots from './bots/reducer';
import common from './common/reducer';
import session from './session/reducer';
import company from './company/reducer';
import organization from './organization/reducer';
import users from './users/reducer';
import permissions from './permissions/reducer';
import recaptcha from './recaptcha/reducer';
import plans from './plans/reducer';
import reports from './reports/reducer';
import triggers from './triggers/reducer';
import items from './items/reducer';
import registerFrom from './registerFrom/reducer';
import messenger from './facebook-messenger/reducer';
import voiceBot from './voice-bots/reducer';
import connectors from './connectors/reducer';
import tags from './tags/reducer';
import variables from './variables/reducer';
import filter from './filter/reducer';
import builder from './builder/reducer';
import intelligence from './intelligence/reducer';
import instagram from './instagram/reducer';
import artificialIntelligence from './artificial-intelligence/reducer';
import rdStation from './rd-station/reducer';
import templates from './templates/reducer';
import ivr from './ivr/reducer';
import ivrVariables from './ivrVariables/reducer';
import ivrFunctions from './ivrFunctions/reducer';
import ivrReports from './ivrReports/reducer';
import ivrSchedules from './ivrSchedules/reducer';
import prompt from './prompt/reducer';
import nps from './nps/reducer';
import topdesk from './topdesk/reducer';
import ivrTags from './ivrTags/reducer';
import ivrChatGPT from './ivrChatGPT/reducer';
import ivrPlans from './ivrPlans/reducer';
import ivrNumbers from './ivrNumbers/reducer';
import ivrTelephoneOperators from './ivrTelephoneOperators/reducer';
import ivrBedrock from './ivrBedrock/reducer';

const appReducer = combineReducers({
  auth,
  tfaStatus,
  bots,
  common,
  session,
  company,
  organization,
  users,
  permissions,
  recaptcha,
  plans,
  reports,
  triggers,
  items,
  registerFrom,
  messenger,
  voiceBot,
  connectors,
  tags,
  variables,
  filter,
  builder,
  intelligence,
  instagram,
  artificialIntelligence,
  rdStation,
  templates,
  ivr,
  ivrVariables,
  ivrFunctions,
  ivrReports,
  ivrSchedules,
  prompt,
  nps,
  topdesk,
  ivrTags,
  ivrChatGPT,
  ivrPlans,
  ivrNumbers,
  ivrTelephoneOperators,
  ivrBedrock,
});

const rootReducer = (state, action) => {
  if (action.type === authTypes.SIGNOUT) {
    localStorage.removeItem('persist:@boteria-frontend');
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
